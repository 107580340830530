<template>
  <div class="merchant-manager-page" v-loading="!state.loaded">
    <div class="title">商户号</div>
    <div class="filter-wrapper">
      <el-button type="primary" size="mini" round icon="el-icon-plus" @click="handleAddClick">添加</el-button>
    </div>
    <div class="table-wrapper">
      <template v-if="!state.list.length && state.loaded">
        <empty-page description="暂无数据"></empty-page>
      </template>
      <template v-else>
        <el-table
          cell-class-name="custom-table-cell merchant-table"
          header-row-class-name="custom-table-header merchant-header"
          :data="state.list"
          v-loading="state.loading"
          style="width: 100%"
        >
          <el-table-column
            align="left"
            header-align="left"
            show-overflow-tooltip
            prop="mchId"
            label="商户Id"
            width="122"
          ></el-table-column>
          <el-table-column
            align="left"
            header-align="left"
            show-overflow-tooltip
            prop="mchName"
            label="商户名称"
            width="142"
          ></el-table-column>
          <el-table-column
            align="left"
            header-align="left"
            prop="mchModel"
            label="类型"
            width="110"
            :formatter="mchModelFormatter"
          ></el-table-column>
          <el-table-column
            align="left"
            header-align="left"
            prop="communityName"
            label="绑定小区"
            width="140"
          ></el-table-column>
          <el-table-column
            align="left"
            header-align="left"
            prop="auditStatus"
            label="状态"
            width="110"
            :formatter="mchModelFormatter"
          >
            <template #default="scope">
              {{ scope.row.auditStatus ? '已审核' : '待审核' }}
            </template>
          </el-table-column>
          <el-table-column align="left" header-align="left" prop="mobile" label="操作" width="110">
            <template #default="scope">
              <span class="edit option" @click="onEdit(scope.row)">编辑</span>
              <span class="delete option" @click="onDelete(scope.row)">删除</span>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <pagination-block
      v-if="state.list?.length"
      @page-change="handleCurrentChange"
      v-model="state.pageNo"
      :page-size="state.pageSize"
      @size-change="handleSizeChange"
      :total="state.count"
    ></pagination-block>
  </div>
</template>
<script>
import { reactive } from 'vue'
import EmptyPage from '@/views/components/empty-page'
import { deleteMerchant, getMerchantList } from '@/apis'
import { useRouter } from 'vue-router'
import router from '@/router'
import { useDeleteConfirm } from '@/hooks/ui'
import PaginationBlock from '@/components/pagination'
export default {
  name: 'merchantManager',
  components: { EmptyPage, PaginationBlock },
  setup() {
    const router = useRouter()
    const { state, onDelete, onEdit, handleCurrentChange, handleSizeChange } = useMerchantManager()
    const handleAddClick = () => {
      router.push({ name: 'createMerchant' })
    }
    const mchModelFormatter = row => {
      return row.mchModel ? '普通商户' : '服务商模式'
    }
    return { state, onDelete, onEdit, handleAddClick, mchModelFormatter, handleCurrentChange, handleSizeChange }
  }
}
function useMerchantManager() {
  const state = reactive({
    list: [],
    loaded: false,
    pageNo: 1,
    pageSize: 10
  })
  const deleteConfirm = useDeleteConfirm()
  const initMerchantList = async () => {
    try {
      const { data } = await getMerchantList({ pageNo: state.pageNo, pageSize: state.pageSize })
      state.list = data.list || []
      state.count = data.count || 0
    } catch (error) {
      console.log('initMerchantList error', error)
    } finally {
      state.loaded = true
    }
  }
  const onEdit = item => {
    if (item.id) {
      router.push({ name: 'createMerchant', params: { id: item.id } })
    }
  }
  const onDelete = async item => {
    if (await deleteConfirm('您确定删除该用户吗?')) {
      await deleteMerchant(item.id)
      initMerchantList()
    }
  }
  const handleCurrentChange = page => {
    state.pageNo = page
    initMerchantList()
  }
  const handleSizeChange = pageSize => {
    state.pageNo = 1
    state.pageSize = pageSize
    initMerchantList()
  }
  initMerchantList()
  return { state, onEdit, onDelete, handleCurrentChange, handleSizeChange }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
